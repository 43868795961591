<template>
  <div class="flex flex-col flex-1">
    <Topbar :pages="pages"></Topbar>

    <div class="flex flex-1 items-center justify-center" v-if="isLoading">Loading...</div>
    <div class="p-6" v-if="!isLoading">
      <div class="w-full h-full">
        <form class="card p-6">
          <div class="mb-2">
            <label class="block mb-2 text-sm font-bold" for="survey_content">
              Survey Name
            </label>
            <input
              class="py-2 px-3 field w-full border"
              type="text"
              name="survey_name"
              v-model="survey.survey_name"
            />
          </div>

          <div>
            <label class="block mb-2 text-sm font-bold" for="survey_content">
              Survey Content
            </label>
            <textarea
              v-model="survey.survey_content"
              id="survey_content"
              class="w-full mb-2 border"
              rows="10"
            ></textarea>
          </div>

          <div>
            <label v-if="survey_sections.length" class="block mb-2 text-sm font-bold border-b pb-2">
              Sections
            </label>
            <div v-for="section of survey_sections" v-bind:key="section.section_id" class="mb-2">
              <div>
                <textarea class="w-full" rows="10" v-model="section.section_content"></textarea>
              </div>
              <!--              <div>-->
              <!--                <label class="block mb-2 text-sm font-bold" for="survey_content">-->
              <!--                  Order Number-->
              <!--                </label>-->
              <!--                <input type="number" v-model="section.ordering" />-->
              <!--              </div>-->
              <div class="text-right">
                <button
                  :disabled="isSubmittingSection"
                  @click="saveSection(section)"
                  class="bg-primary-green text-white px-4 py-2"
                >
                  {{ isSubmittingSection ? 'Submitting' : 'Save Section' }}
                </button>
              </div>
            </div>
            <div>
              <label class="block mb-2 text-sm font-bold border-b pb-2">
                New Sections
              </label>
              <div>
                <textarea class="w-full" rows="10" v-model="new_section.section_content"></textarea>
              </div>
              <div class="text-right">
                <button
                  @click="saveSection(new_section)"
                  :disabled="isSubmittingSection"
                  class="bg-primary-green text-white px-4 py-2"
                >
                  {{ isSubmittingSection ? 'Submitting' : 'Save Section' }}
                </button>
              </div>
            </div>
          </div>

          <div class="flex items-center">
            <button
              @click="saveSurvey()"
              type="button"
              :disabled="isSubmitting"
              class="font-bold focus:outline-none focus:shadow-outline text-white bg-primary-green px-4 py-2"
            >
              {{ isSubmitting ? 'Submitting' : 'Save Survey' }}
            </button>
          </div>
        </form>
      </div>
    </div>
  </div>
</template>

<script>
import {v4 as uuidv4} from 'uuid';
import orderBy from 'lodash-es/orderBy';
import API, {graphqlOperation} from '@aws-amplify/api';
import {createSurveySection} from '@/graphql/mutations';
import {getSurvey, listSurveySections} from '@/graphql/queries';
import {updateSurvey, updateSurveySection} from '@/graphql/mutations';
import {useToast} from 'vue-toastification';

export default {
  data() {
    return {
      survey: null,
      isLoading: true,
      isSubmitting: false,
      isSubmittingSection: false,
      survey_sections: null,
      new_section: {section_content: '{}'},
    };
  },
  computed: {
    id() {
      return this.$route.params.id;
    },
    pages() {
      return [
        {
          name: 'Surveys',
          to: {name: 'surveys'},
          current: false,
        },
        {
          name: this.survey ? `Edit - ${this.survey.survey_name}` : 'Edit Survey',
          to: {name: 'surveys.edit', params: {id: this.id}},
          current: true,
        },
      ];
    },
  },
  methods: {
    setFilterMode(mode) {
      this.filter_mode = mode;
    },
    async loadSurvey() {
      this.isLoading = true;
      await API.graphql(graphqlOperation(getSurvey, {id: this.id})).then(({data: {getSurvey}}) => {
        this.survey = getSurvey;
        this.survey.survey_content_parsed = JSON.parse(this.survey.survey_content);
        API.graphql(graphqlOperation(listSurveySections, {survey_id: this.id})).then(
          ({
            data: {
              listSurveySections: {items},
            },
          }) => {
            this.survey_sections = orderBy(items, 'ordering');
            this.isLoading = false;
          },
        );
      });
    },
    async saveSurvey() {
      this.isSubmitting = true;
      delete this.survey.survey_content_parsed;
      delete this.survey.survey_stages;
      delete this.survey.createdAt;
      delete this.survey.updatedAt;
      delete this.survey._deleted;
      delete this.survey._lastChangedAt;
      const toast = useToast();
      await API.graphql(graphqlOperation(updateSurvey, {input: this.survey}))
        .then(({data: {updateSurveys}}) => {
          console.log(updateSurveys);
          this.isSubmitting = false;
          toast.success('Survey Updated Successfully', {
            timeout: 5000,
          });
        })
        .catch(() => {
          this.isSubmitting = false;
        });
      this.survey.survey_content_parsed = JSON.parse(this.survey.survey_content);
    },
    async saveSection(section) {
      this.isSubmittingSection = true;
      delete section._lastChangedAt;
      delete section.createdAt;
      delete section.updatedAt;
      delete section._deleted;
      section.survey_id = this.id;
      const toast = useToast();
      if (section.section_id === undefined) {
        section.section_id = uuidv4();
        section.id = uuidv4();
        section.ordering = parseInt(section.ordering, 10);
        const surveyContent = JSON.parse(section.section_content);
        section.section_content = JSON.stringify(surveyContent);
        await API.graphql(graphqlOperation(createSurveySection, {input: section})).then(() => {
          this.new_section = {section_content: ''};
          this.isSubmittingSection = false;
          toast.success('Section Updated Successfully', {
            timeout: 5000,
          });
          this.loadSurvey();
        });
      } else {
        if (section.id === undefined) {
          section.id = uuidv4();
        }
        const surveyContent = JSON.parse(section.section_content);
        section.section_content = JSON.stringify(surveyContent);
        await API.graphql(graphqlOperation(updateSurveySection, {input: section})).then(() => {
          this.isSubmittingSection = false;
          toast.success('Section Updated Successfully', {
            timeout: 5000,
          });
          this.loadSurvey();
        });
      }
    },
  },
  mounted() {
    this.loadSurvey();
  },
};
</script>
